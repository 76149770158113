<template>
  <div style="margin: 30px"></div>
  <van-cell-group inset>
    <van-field v-model="userName" label="用户名" placeholder="请输入用户名" maxlength="8" />
    <!-- <van-field
      v-model="realName"
      label="真实姓名"
      placeholder="请输入真实姓名"
      maxlength="8"
    /> -->
    <van-field
      v-model="idcardNo"
      label="身份证号"
      placeholder="请输入身份证号码"
      maxlength="18"
    />
    <van-cell
      title=""
      size="large"
      label="山东省财政厅非税系统要求缴费非税的用户必须填写身份证号码，用于核对缴费用户有效性，请认真填写。"
    />
  </van-cell-group>

  <div style="margin: 20px 15px">
    <van-button round block color="#FD962D" plain native-type="submit" @click="saveInfo">
      保存
    </van-button>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { Cell, CellGroup, Toast } from "vant";
import {
  checkIdNum,
  getCookie,
  isAndroid,
  safeName,
  setCookie,
} from "../common/js/utils";
import api from "../service/api";
import { getStorage, setStorage } from "../common/js/storeUtil";

export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  setup() {
    const router = useRouter(); //传值
    const userName = ref("");
    const realName = ref("");
    const idcardNo = ref("");

    //idcard中间加*号
    const idCardStr = (str) => {
      if (str.length == 18) {
        return str.substring(0, 6) + "********" + str.substring(14, str.length);
      } else if (str.length == 15) {
        return str.substring(0, 6) + "*****" + str.substring(12, str.length);
      } else {
        return "";
      }
    };
    onMounted(() => {
      /* eslint-disable */
      //setTitle("用户信息");
      userName.value = getStorage("userName");
      realName.value = safeName(getStorage("realName"));
      idcardNo.value = idCardStr(getStorage("idcardNo"));
    });
    function checkName(name) {
      var reg = /^[\u4e00-\u9fa5]{2,8}$/;
      return reg.test(name);
    }

    function checkNickname(name) {
      var reg = /^[\u4e00-\u9fa5a-zA-Z0-9_]{2,8}$/;
      return reg.test(name);
    }
    const saveInfo = () => {
      if (!checkNickname(userName.value)) {
        Toast("请重新编辑2-8位用户名");
        return;
      }
      if (!checkName(realName.value)) {
        Toast("真实姓名只能2-8位是汉字");
        return;
      }
      if (!checkIdNum(idcardNo.value)) {
        Toast("请仔细核对重新编辑证件号码");
        return;
      }

      api
        .addIdentity({
          name: userName.value,
          real_name: realName.value,
          idcard_no: idcardNo.value,
        })
        .then((res) => {
          if (res.data.code == 1001) {
            Toast("更新成功");
            setStorage("userName", userName.value);
            setStorage("realName", realName.value);
            setStorage("idcardNo", idcardNo.value);

            router.go(-1);
          } else {
            Toast(res.data.message);
          }
        })
        .catch((err) => {
          Toast(err.message);
        });
    };

    return {
      userName,
      realName,
      idcardNo,
      saveInfo,
    };
  },
};
</script>

<style lang="less"></style>
>
